import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import rehypeReact from 'rehype-react'
import Link from '../rehype/Link'

const Page = ({ pageData, list }) => {
  const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: { a: Link },
  }).Compiler

  return (
    <Fragment>
      <section className="c-section c-section--pageHeader">
        <div className="c-section__outer container">
          <div className="c-section__inner pb-3">
            <div className="c-pageHeader">
              <div className="row">
                <div className="col-12">
                  <h1 className="c-pageHeader__title">{pageData.title}</h1>
                </div>
                <div
                  className="col-12 mb-4"
                  dangerouslySetInnerHTML={{ __html: pageData.intro }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="c-section c-section--resourcesList">
        <div className="container">
          <div className="c-resourcesList c-resourcesList--print">
            {list.map(({ node }) => (
              <div key={node.name} className="c-resourcesItem">
                <div className="row">
                  <div className="col-12 col-lg-4 text-center">
                    {node.logo && (
                      <img
                        src={node.logo.fixed.src}
                        srcSet={node.logo.fixed.srcSet}
                        className="c-resourcesItem__thumbnail img-fluid"
                      />
                    )}
                  </div>
                  <div className="col-12 col-lg-8">
                    <h2 className="c-resourcesItem__title">{node.name}</h2>
                    <div className="c-resourcesItem__info">
                      {renderAst(
                        node.descriptionNode.childMarkdownRemark.htmlAst
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Fragment>
  )
}

Page.defaultProps = {
  list: [],
}

Page.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
}

export default Page
