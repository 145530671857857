import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import _orderBy from 'lodash/orderBy'

import Page from '../components/Page'
import SEO from '../components/SEO'
import Schema from '../components/Schema'
import ConsultantPage from '../components/industry-partners/Page'
import Newsletter from '../components/Newsletter'

const IndustryConsultantsPage = () => {
  const {
    markdownRemark,
    allDatoCmsIndustryConsultant,
  } = useStaticQuery(graphql`
    query IndustryConsultantsPageQuery {
      markdownRemark(frontmatter: { path: { eq: "/industry-consultants" } }) {
        frontmatter {
          title
          path
          intro
        }
      }
      allDatoCmsIndustryConsultant(sort: { fields: name, order: ASC }) {
        edges {
          node {
            name
            descriptionNode {
              childMarkdownRemark {
                htmlAst
              }
            }
            logo {
              fixed(width: 500, imgixParams: { fm: "jpg", auto: "compress" }) {
                ...GatsbyDatoCmsFixed
              }
            }
          }
        }
      }
    }
  `)

  const pageData = markdownRemark.frontmatter

  const allConsultants = allDatoCmsIndustryConsultant.edges.map(({ node }) => ({
    node: { ...node, _name: node.name.toLowerCase() },
  }))
  const list = _orderBy(allConsultants, 'node._name', 'asc')

  return (
    <Page className="p-industryResources p-industryResources--icons has-fingerPrints">
      <SEO title={pageData.title} />
      <Schema
        breadcrumbs={[
          {
            name: pageData.title,
            uri: pageData.path,
          },
        ]}
      />

      <ConsultantPage pageData={pageData} list={list} />

      <Newsletter />
    </Page>
  )
}

export default IndustryConsultantsPage
